import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useStaticQuery, graphql, navigate, Link } from 'gatsby'
import { useRecoilState } from 'recoil'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap, Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'

import DropHeader from '../shared/DropHeader'
import { Headline, LargeP, SmallP } from '../shared/text'
import { PrimaryButton } from '../shared/buttons'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'
import scrollToTop from '../../utils/scrollToTop'

import { productDetailState } from '../../actions/products'

import { trackProductClickEvent, trackCollectionViewEvent } from '../../utils/event-tracking'

const wiggle = keyframes`
  0% { transform: rotate(0deg); }
  30% { transform: rotate(2deg) scale(1.1); }
  70% { transform: rotate(-2deg) scale(1.1); }
  100% { transform: rotate(0deg); }
`

const Section = styled.section`
  width: 100%;
  height: fit-content;

  background-color: ${colors.white};
  position: relative;

  ${media.tablet`
    padding: 0 0 80px 0;
  `}

  h1 {
    color: ${colors.navy};
    ${media.desktop`
      margin-bottom: 60px;
    `}
  }

  .dropheader__container {
    button {
      color: ${colors.navy};
      border: 1px solid ${colors.navy};

      svg {
        fill: ${colors.navy};
      }
    }
  }

  .productGroups {
    button {
      background-color: ${colors.navy};
      border: 1px solid ${colors.navy};
    }
  }
`

const Grid = styled.div`
  width: 100%;

  justify-content: center;

  margin: 0 auto;

  ${media.mobile`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 0;
  `}
  ${media.tablet`
    // display: flex;
    // flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 60px 30px;

    max-width: 750px;
    padding-top: 60px;
  `}
  ${media.desktop`
    gap: 100px 85px;
    padding: 0;
    width: 100%;
    max-width: 750px;
    grid-template-columns: repeat(3, 300px);
  `}
`

const OutOfStockBadge = styled.div`
  background-color: ${colors.orange};
  border-radius: 50%;
  display: grid;
  place-items: center;
  height: 60px;
  width: 60px;
  z-index: 1;
  position: absolute;
  top: -10px;
  right: -10px;
  color: ${colors.navy} !important;
  font-family: ${fonts.larsseit.medium};
  font-size: 16px;
  line-height: 16px;
`

const ProductGroup = styled(Link)`
  display: grid;
  place-items: center;
  grid-row-gap: 12px;
  text-decoration: none;
  color: ${colors.navy};

  text-align: center;
  margin: 0 auto;

  & img {
    transition: 200ms all ease;
  }

  &:hover {
    img {
      animation: ${wiggle} 250ms;
    }
    button {
      transform: scale(1.07);
    }
  }

  img,
  button {
    pointer-events: none;
  }

  ${media.mobile`
    padding: 24px 12px 32px 12px;
    height: 100%;
  `}
  ${media.tablet`
    padding: 0;
    border: none;
    height: fit-content;
  `}
  ${media.desktop`
    grid-gap: 15px;
  `}
`

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: auto;
  position: relative;
  background-color: #f9f9f9;

  ${media.mobile`
    width: 130px;
    padding: 20px 10px;
    border-radius: 10px;
  `}
  ${media.tablet`
    width: 180px;
  `}
  ${media.desktop`
    width: 300px;
    padding: 40px;
    min-height: 300px;
    border-radius: 20px;
  `}
`

const Description = styled(SmallP)`
  color: ${colors.slate};
  line-height: 1.4 !important;
  text-align: center;
  max-width: 275px;

  ${media.mobile`
    font-size: 10px;
  `}
  ${media.tablet`
    display: block;
    font-size: 14px;
  `}
  ${media.desktop`
    font-size: 15px;
  `}
`

const PriceText = styled(LargeP)`
  line-height: 1 !important;
  color: ${colors.orange};

  ${media.mobile`
    font-size: 18px !important;
  `}
  ${media.desktop`
    font-size: 30px !important;
    margin-top: -6px;
  `}
`

const ProductName = styled(Headline)`
  //white-space: nowrap;

  ${media.mobile`
    font-size: 18px !important;
  `}
  ${media.desktop`
    font-size: 30px !important;
  `}
`

const PointerWrapper = styled.div`
  cursor: pointer;
`
const query = graphql`
  query {
    poopbag: file(relativePath: { regex: "/accessories/poop-bag.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    poopbagholder: file(relativePath: { regex: "/accessories/poop-bag-holder.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
  }
`

interface SinglesProps {
  isProductLibrary?: boolean
  title?: string
  buttonCopy?: string
  isPdp?: boolean
  isAlternate?: boolean
  isHomePage?: boolean
  sustain?: boolean
}

const Products = (props: SinglesProps) => {
  const { title, buttonCopy, isPdp } = props

  const images = useStaticQuery(query)
  const [productMap] = useRecoilState(productDetailState)
  const slugMap = productMap?.bySlug

  const products = [
    {
      img: getImage(images.poopbag),
      name: 'Poop Bags',
      description:
        'USDA-certified and Plastic Negative so you can tackle mess without messing up the planet. ',
      slug: 'poop-bags',
      outOfStock: false
    },
    {
      img: getImage(images.poopbagholder),
      name: 'Poop Bag Holder',
      description:
        'A durable canvas holder that conveniently clips onto leashes and bags for a hassle-free cleanup, every time.&nbsp;',
      slug: 'clip-on-canvas-poop-bag-holder',
      outOfStock: false
    }
    // {
    //   img: getImage(images.bone),
    //   name: 'Bone Broth Plus',
    //   description:
    //     'Packed with collagen & amino acids to promote joint health and long-term vitality.',
    //   slug: 'bone-broth-plus',
    //   outOfStock: false
    // }
  ]

  let displayedProducts = products

  const { fetching } = productMap

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.fromTo(
      '.productGroups',
      { opacity: 0, y: 50 },
      {
        scrollTrigger: '.productGroups',
        ease: Power3.easeOut,
        opacity: 1,
        duration: 1,
        y: 0,
        stagger: 0.25
      }
    )
    trackCollectionViewEvent(
      productMap,
      products.map((product) => product.slug)
    )
  }, [])

  const trackProductClick = (slug: string) => {
    if (!productMap.fetching) {
      trackProductClickEvent(productMap, slug, isPdp)
    }
  }

  let currentPathname = ''
  if (typeof window !== 'undefined') currentPathname = window.location.pathname

  return (
    <Section>
      {title && <DropHeader title={title} fontSize={133} tabletFontSize={88} mobileFontSize={50} />}
      <Grid className="productGroups">
        {slugMap &&
          displayedProducts.map((product) => {
            const productDetail = slugMap[product.slug]
            const OTPPrice = productDetail?.single?.pricing?.oneTime?.price || 0
            const hideProduct = currentPathname === `/products/${product.slug}`

            if (hideProduct) return null

            return (
              <ProductGroup
                className="productGroup"
                key={product.name}
                to={`/products/${product.slug}`}
                onClick={() => trackProductClick(product.slug)}
              >
                <PointerWrapper>
                  <ImgWrapper>
                    {Boolean(product.outOfStock) && (
                      <OutOfStockBadge>
                        Sold <br />
                        Out
                      </OutOfStockBadge>
                    )}
                    <GatsbyImage
                      image={product.img}
                      alt={`${product.name} tin`}
                      style={{ height: '100%', width: '100%' }}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </ImgWrapper>
                </PointerWrapper>

                <PointerWrapper>
                  <ProductName as="p">{product.name}</ProductName>
                </PointerWrapper>

                <PriceText>{OTPPrice > 0 ? `$${OTPPrice.toFixed(2)}` : 'Out of Stock'}</PriceText>

                <Description>{product.description}</Description>

                {product.outOfStock ? (
                  <PrimaryButton copy="Out of Stock" bg={colors.mediumGrey} />
                ) : (
                  <PrimaryButton bg={colors.navy} color={colors.lightText} copy="Shop Now" arrow />
                )}
              </ProductGroup>
            )
          })}
      </Grid>
    </Section>
  )
}

export default Products
